.App {
  /*text-align: center;*/
  background-color: #f2f1ef;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  position: fixed;
  z-index: 1;
  top: 0;
  background-color: #222;
  height: 70px;
  width: 100vw;
  padding: 20px;
  color: #f2f1ef;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.headerBtn:visited {
  color: #f2f1ef;
}

.headerBtn {
  color: white;
  text-decoration: none;
  font-size: 36px;
  margin: 0 20px;
}

.selected {
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.App-intro {
  font-size: large;
}

.eliHeader {
  position: absolute;
  left: 30px;
}

.hamburger-icon {
  margin-top: 15px;
  margin-right: 60px;
  float: right;
  display: none;
}

.hamburger-icon:hover {
  cursor: pointer;
}

.hamburger-icon img {
  height: 50px;
}

.page-wrapper {
  padding-top: 115px;
  text-align: center;
  padding-bottom: 50px;
}

nav {
  float: right;
  margin-top: 17px;
  margin-right: 60px;
}

@media (max-width: 1000px) {
  nav {
    display: none;
  }

  .hamburger-icon {
    display: block;
  }
}

.cover {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 2;
  opacity: .3;
}
