body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html, body, #root, .App {
  height: 100%;
  background-color: #f2f1ef;
}
