.map {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
}
