.page-header {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
    font-family: 'Open Sans', sans-serif;
    min-width: 400px;
    width: fit-content;
    margin: 20px auto;
    font-size: 70px;
    font-weight: 300;
    transition: .3s;
    padding: 0 10px;
  }
  