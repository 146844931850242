.conway-wrapper {
  display: flex;
  justify-content: center;
}

.conway-title {
  font-family: 'Open Sans', sans-serif;
  width: 550px;
  margin: 20px auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-size: 50px;
  font-weight: 400;
}

.side-section {
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  margin: 0 10px;
}

.clear-generate {
  display: flex;
  margin-top: 7px;
  justify-content: space-between;
}

.game-btn {
  padding: 5px 15px;
  font-size: 20px;
  background-color: #bfbfbf;
  border-radius: 7px;
}

.st-btn {
  padding: 10px;
  width: 100%;
}

.sm-btn {
  width: 197px;
}

.game-prep {
  font-family: 'Open Sans', sans-serif;
}

.rule-header {
  font-family: 'Open Sans', sans-serif;
  margin: 15px 0;
  font-size: 25px;
}

.rules {
  padding: 5px;
}

.rules p {
  margin-top: 0;
}

.rules li {
  margin: 3px 0;
}

button:focus {
  outline: none;
}

button:hover, .st-btn:focus {
  background: #222;
  color: #fff;
}

button:active {
  transform: scale(0.99);
}

@media (max-width: 1000px) {
  .conway-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .side-section {
    margin-top: 10px;
  }
}